<template>
  <div class="wrapper">
    <header>输入{{formData.khmc}}的加工单</header>
    <!-- 选品名,输数量单价 品名增删改-->
    <div class="split">
      <el-row :gutter="4">
        <el-col :span="12"><el-input v-model="formData.shdz" placeholder="送货地址"></el-input></el-col>
        <el-col :span="8">合计:{{formData.xh}}</el-col>
        <el-col :span="4"><el-input v-model="inputVal3" @input="hh_inputChange"></el-input></el-col>
      </el-row>
    </div>
    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
      <lzqTable 
        :tableData_hh_dh="tableData_xm_gx" mytitle="加工单品名资料表头" 
        :IsSelection="false" RowKey="item6"
        @tableRowClick="tableRowClick($event)"
        @handleSelectionChange="handleSelectionChange($event)"
      >
      </lzqTable>
      </div>
    </section>
    <!-- 中间单据弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_dj" width="90%">
      <el-row justify="center">
        <el-col :span="10"><span class="mybt">{{formData.pm}}</span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="品名" label-width="90px"><el-input disabled v-model="formData.pm" style="width:200px" /> </el-form-item>
          <el-form-item label="数量" label-width="90px"><el-input type="number" v-model="formData.sl" style="width:200px" /> </el-form-item>
          <el-form-item label="单价" label-width="90px"><el-input type="number" v-model="formData.dj" style="width:200px" /> </el-form-item>
          <el-form-item label="金额" label-width="90px"><el-input type="number" v-model="formData.je" style="width:200px" /> </el-form-item>
          <el-form-item label="单位" label-width="90px"><el-input v-model="formData.dw" style="width:200px" /> </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="10"><el-button type="success" @click="my_save_dj" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间单据弹窗结束 -->
    <!-- 中间添加品名资料弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_pm" width="90%">
      <el-row justify="center">
        <el-col :span="10"><span class="mybt">{{formData.qxxm}}加工品名</span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="品名" label-width="90px"><el-input v-model="formData.pm" style="width:200px" /> </el-form-item>
          <el-form-item label="单位" label-width="90px"><el-input v-model="formData.dw" style="width:200px" /> </el-form-item>
          <el-form-item label="单价" label-width="90px"><el-input type="number" v-model="formData.dj" style="width:200px" /> </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="10"><el-button type="success" @click="my_add_xg_del_pm" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间添加品名资料弹窗结束 -->
    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <!-- <el-button type="success" @click="btn_add_xg_del('新增')">新增品名</el-button>
          <el-button type="warning" @click="btn_add_xg_del('修改')">修改</el-button>
          <el-button type="danger" @click="btn_add_xg_del('删除')">删除</el-button> -->
          <el-button type="warning" @click="goBack">返回</el-button>
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import { ref, reactive, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from '../truecomponents/lzqTable.vue'//引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const route = useRoute();
const router = useRouter();
const dialogFormVisible_dj = ref(false);
const dialogFormVisible_pm = ref(false);
const myloading = ref(false);
const my_row = ref()
const inputVal3 = ref("");

const formData = ref({//表格生成
  rq: "",
  dzkhid: "", //订制客户ID
  dzcpid: "", //订制产品ID
  jgkhid: "", //加工客户ID
  jgcpid: "", //加工产品ID
  azdz: "", //安装地址
  azqy: "", //安装区域
  bz: "", //备注
  bm: "", //编码
  clry: "", //测量人员
  dh: "", //单号
  dj: "", //单价
  dw: "", //单位
  dh: "", //电话
  gd: "", //高度
  gg: "", //规格
  je: "", //金额
  jsr: "", //经手人
  kdrq: "", //开单日期
  khdz: "", //客户地址
  khdh: "", //客户电话
  khmc: "", //客户名称
  kd: "", //宽度
  lldh: "", //联络电话
  lxr: "", //联系人
  lxrdh: "", //联系人电话
  mlhclmc: "", //面料或材料名称
  pm: "", //品名
  spbm: "", //商品编码
  spqm: "", //商品全名
  sjazrq: "", //实际安装日期
  sl: "", //数量
  shdz: "", //送货地址
  xdrq: "", //下单日期
  xh: "", //序号
  yqazrq: "", //要求安装日期
  zdr: "", //制单人
  cztj: "tjjczl", //操作条件
  yhmc: "", //token
  qxxm: "", //权限项目
  id: "1", //
  endrq: "", //结束日期
});

watchEffect(() => {
  const x1 = formData.value.sl;
  const x2 = formData.value.dj;
  if(formData.value.sl*1.00 != 0.00){
    formData.value.je = parseFloat((x1 * x2).toFixed(2))//parseInt(x1 * x2) + parseInt(x3 == "" ? 0 : x3);
  }
});

onMounted(() => {//页面进入时
  console.log(JSON.parse(route.query.my_row));
  my_row.value=JSON.parse(route.query.my_row)
  formData.value.khmc=my_row.value.item1
  formData.value.shdz=my_row.value.item2
  formData.value.jgkhid=my_row.value.item3//加工客户id
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  formData.value.cztj = "手机端进入界面先新增一张空加工单";
  khjczl_get();
  // setTimeout(() => {
  //   formData.value.cztj = "发货单获取控价名称";
  //   khjczl_get();
  // }, 500);
});

const btn_add_xg_del = (tj) => {//三个按钮的操作
  switch (tj) {
    case "新增":
      formData.value.pm = ""
      formData.value.dw = ""
      formData.value.dj = ""
      formData.value.qxxm = "新增"
      dialogFormVisible_pm.value=true//显示弹窗
      break;
    case "修改":
      if(formData.value.pm == ""){return}
      formData.value.qxxm = "修改"
      dialogFormVisible_pm.value=true//显示弹窗
      break;
    case "删除":
      if(formData.value.pm == ""){return}
      my_del()//删除
      break; 
  }
}

const my_del = () =>{//删除一行品名
  ElMessageBox.confirm(
    "[" + formData.value.pm + "/" + formData.value.dw+ "/" + formData.value.dj + "/" + "]修改或删除?",
    "修改或删除?",
    { confirmButtonText: "确认删除", cancelButtonText: "取消删除", type: "error", }
  )
    .then(() => {
      formData.value.cztj = "手机端删除一行加工品名资料";
      myGtdatafun
        .MygettableData("html_dysj_ZSG.php", formData.value)
        .then(function (response) {
          console.log(response);
          location.reload(); //重新加载本页
        });
      tableData_xm_gx.splice(
        tableData_xm_gx.findIndex((e) => e.item4 === formData.value.id),
        1
      ); // 将删除id等于1的选项
    })
    .catch(() => {
      console.log('取消删除');
      // dialogFormVisible_dj.value = !dialogFormVisible_dj.value;
      // formData.value.khmc=row.item1
      // formData.value.shdz=row.item2
    });
}

const my_add_xg_del_pm = () => {//保存品名新增和修改
  console.log('my2_add_xg',formData.value);
  if (formData.value.pm == "") {
    ElMessage({ message: '品名不能为空,保存不成功!', type: 'error', });
    return;
  }
  //客户名称重复了,不保存
  let cansavekh = 'yes';
  if(formData.value.qxxm == "新增"){
    tableData_xm_gx.forEach((ite, index) => {
      if (ite.item1 == formData.value.pm) {
        ElMessage({ message: '品名重复了,保存不成功!', type: 'error', });
        cansavekh = 'no';
      }
    });
  }
  if (cansavekh == 'no') {
    dialogFormVisible_pm.value = false;//隐藏弹窗
    formData.value.pm = ""; formData.value.dw = ""; formData.value.dj = "";
    return;
  }
  myloading.value = true
  if(formData.value.qxxm == "新增"){formData.value.cztj = "手机端新增加工品名资料";}//==要注意
  if(formData.value.qxxm == "修改"){formData.value.cztj = "手机端修改加工品名资料";}
  khjczl_get();
  dialogFormVisible_pm.value = false;
  formData.value.khmc = ""; formData.value.shdz = "";
} 

const my_save_dj = () => {//保存单据
  console.log('m1y_add_xg',formData.value);
  let cansavekh = 'yes';
  if (formData.value.sl == "" && formData.value.je == "") {
    ElMessage({ message: '数量金额不能都为空,保存不成功!', type: 'error', });
    return;
  }
  if (formData.value.sl == "") {formData.value.sl =0}
  if (formData.value.je == "") {formData.value.je =0}
  myloading.value = true
  formData.value.cztj = "手机端保存加工单输入的数量单价等";
  khjczl_get();
} 

const handleSelectionChange = (arr) => {//选择复选框
  console.log('handleSelectionChange[]', arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.pm = item.item1
    formData.value.dw = item.item5
    formData.value.dj = item.item3
    formData.value.jgcpid = item.item6
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
}

const tableRowClick = (row) => {//点击一行跳转
  console.log(row.item4);
  formData.value.pm=row.item1//品名
  formData.value.dw=row.item5//单位
  formData.value.dj=row.item3//单价
  formData.value.sl=(row.item2*1.00==0?'':row.item2)//数量
  formData.value.je=(row.item4*1.00==0?'':row.item4)//金额
  formData.value.jgcpid=row.item6//加工产品id
  formData.value.jgkhid=row.item7//加工客户id
  formData.value.id=row.item8//单据id

  dialogFormVisible_dj.value = true;
  console.log('输入数量单价金额',row,formData.value);
  // if (row.item1 == '查无资料') return
  // ElMessageBox.confirm(
  //   "[" + row.item1 + "/" + row.item2 + "/" + "]修改或删除?",
  //   "修改或删除?",
  //   {
  //     confirmButtonText: "删除",
  //     cancelButtonText: "修改",
  //     type: "error",
  //   }
  // )
  //   .then(() => {
  //     formData.value.id = row.item3;
  //     formData.value.cztj = "手机端删除一行加工客户资料";
  //     myGtdatafun
  //       .MygettableData("html_dysj_ZSG.php", formData.value)
  //       .then(function (response) {
  //         console.log(response);
  //         location.reload(); //重新加载本页
  //       });
  //     tableData_xm_gx.splice(
  //       tableData_xm_gx.findIndex((e) => e.item8 === row.item8),
  //       1
  //     ); // 将删除id等于1的选项
  //   })
  //   .catch(() => {
  //     console.log('这是修改',row,formData.value);
  //     dialogFormVisible_dj.value = !dialogFormVisible_dj.value;
  //     formData.value.khmc=row.item1
  //     formData.value.shdz=row.item2
  //   });
};

const khjczl_get = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      console.log(formData.value, response);
      myloading.value = false
      if (response.length <= 0) { return }
      tableData_xm_gx.length = 0;
      tableData_xm_gx_base.length = 0;
      let zje=0
      response.forEach((item) => {
        tableData_xm_gx.push(item);
        tableData_xm_gx_base.push(item);
      });
      tableData_xm_gx.forEach((item) => {
        zje=zje+ parseFloat((item.item4*1.00).toFixed(2))//本单总金额
      });
      formData.value.xh = zje
      
      dialogFormVisible_dj.value = false;
      dialogFormVisible_pm.value = false;
      if(formData.value.qxxm != "")
      {location.reload();}//重新加载本页 
    })
    .catch(function (error) {
    });
};

const goBack = () => {
  router.go(-1)
};
const toHome = () => {
  router.push("/");
};

const hh_inputChange = async (e) => {//本页面筛选
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item2.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item3.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};

</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin: 6px; margin-bottom: 10px; display: flex;
  justify-content: space-between; align-items: center;
}
</style>